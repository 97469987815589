@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Outfit:wght@100..900&family=Paytone+One&display=swap');

body {
  margin: 0;
  padding : 0; 
  font-family: "Outfit", serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-block-start : 0;
  margin-block-end : 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px; /* Adjust width for vertical scrollbar */
  height: 8px; /* Adjust height for horizontal scrollbar */
  }

  ::-webkit-scrollbar-thumb {
      background-color: #aaa; /* Color of the scrollbar thumb */
      border-radius: 10px; /* Rounded corners of the scrollbar thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
      background-color: #888; /* Color of the scrollbar thumb on hover */
  }

  ::-webkit-scrollbar-track {
      background-color: #f0f0f0; /* Color of the scrollbar track */
      border-radius: 10px; /* Rounded corners of the scrollbar track */
}

.color-black-dark {
  color: #212121;
}

.color-black-medium {
  color: #555555;
}

.color-black-light {
  color: #A7A7A7;
}

.color-main-blue {
  color : #009DFF;
}

h1 {
    font-size : 16px;
    font-weight: normal;
    padding : 0;
    margin : 0;
    font-weight : 400;
}

h2 {
    font-size : 14px;
    font-weight: normal;
    padding : 0;
    margin : 0;
    font-weight : 400;
    color : #555555;
}

h3 {
    font-size : 14px;
    font-weight: normal;
    padding : 0;
    margin : 0;
    font-weight : 400;
    color : #555555;
}

h4 {
    font-size : 12px;
    font-weight: normal;
    padding : 0;
    margin : 0;
    color : #555555;
}

.status-red {
  color:  rgb(240, 42, 42); 
  background-color:  rgba(235, 0, 0, 0.2); 
}

.status-amber {
  color:  rgb(255, 145, 0); 
  background-color:  rgba(255, 191, 0, 0.2); 
}

.status-green {
  color:  rgb(0, 200, 0); 
  background-color:  rgba(0, 200, 0, 0.2); 
}

.status-grey {
  color : white;
  background-color:  rgba(27, 27, 27, 0.404); 
}

.status-blue {
  color : white;
  background-color:  rgba(0, 157, 255, 0.8); 
}